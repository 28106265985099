body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* line 59, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap h4 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  color: #000a2d;
  display: inline-block;
  position: relative;
  padding-left: 68px;
}

/* line 69, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap h4::before {
  position: absolute;
  content: "";
  width: 54px;
  height: 2px;
  background: #eb566c;
  top: 0;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 81, C:/Users/HP/Desktop/264.Business_HTML/HTML/assets/scss/_team.scss */
.about-details .about-details-cap p {
  color: #464d65;
  font-size: 14px;
}
