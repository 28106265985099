body {
  align-items: center;
  justify-content: center;
}

/* $animationSpeed: 40s; */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;

  .slide-track {
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slide {
    height: 100px;
    width: 250px;
  }
}
