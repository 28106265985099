.address {
  color: "azure";
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1rem;
}

.responsive-map {
  overflow: hidden;
  height: 0;
  padding-bottom: 50%;
  position: relative;
}

.responsive-map iframe {
  border: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
